import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import 'ol/ol.css';
import { Feature, Map, Overlay, View } from 'ol';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';

import './OlMap.scss';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import { Circle as CircleStyle } from 'ol/style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import { ArtistAddress, Artist } from '../../api/artist';
import Point from 'ol/geom/Point';
import { AppState } from '../../redux/store';
import { clearSelectedArtistId, setSelectedArtistId } from '../../redux/mapSlice';
import { Link } from 'react-router-dom';

interface IProps {
  artists: Array<Artist>;
}

const marker = new Style({
  image: new CircleStyle({
    radius: 10,
    fill: new Fill({ color: 'red' }),
    stroke: new Stroke({
      color: 'black',
      width: 1,
    }),
  }),
});

class ArtistFeature extends Feature {
  _id: string;
  name: string;
  craft: string;
  active: boolean;
  street: string;
  town: string;
  constructor(id: string, name: string, craft: string, active: boolean, exhibitionaddress: ArtistAddress) {
    super({
      type: 'icon',
      geometry: new Point(fromLonLat([exhibitionaddress.lon, exhibitionaddress.lat])),
    });
    this.setId(id);
    this._id = id;
    this.name = name;
    this.craft = craft;
    this.active = active;
    this.street = exhibitionaddress.street;
    this.town = exhibitionaddress.town;
  }
}

const OlMap: React.FC<IProps> = (props: IProps) => {
  const [olMap, setOlMap] = useState<Map>();
  const [featuresLayer, setFeaturesLayer] = useState<VectorLayer<any>>();
  const [features, setFeatures] = useState<Array<ArtistFeature>>();
  const [popup, setPopup] = useState<Overlay>();
  const [popupContent, setPopupContent] = useState<JSX.Element>();

  const mapElementRef = useRef<HTMLDivElement>(null);
  const popupElementRef = useRef<HTMLDivElement>(null);
  const popupContentRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<Map>();
  const popupOverlayRef = useRef<Overlay>();

  const dispatch = useAppDispatch();
  const selectedArtistId = useAppSelector((state: AppState) => {
    return state.map.selectedArtistId;
  });

  mapRef.current = olMap;
  popupOverlayRef.current = popup;

  useEffect(() => {
    if (props.artists) {
      const activeArtists = props.artists.filter((artist) => {
        return artist.exhibitionaddress.active;
      });
      const features: Array<ArtistFeature> = activeArtists.map((artist) => {
        return new ArtistFeature(artist._id, artist.name, artist.craft, artist.active, artist.exhibitionaddress);
      });
      setFeatures(features);
    }
  }, [props.artists]);

  useEffect(() => {
    const initalFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
      style: marker
    });
    const popup = new Overlay({
      element: popupElementRef.current!,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      }
    });
    const initialOlMap = new Map({
      layers: [
        new TileLayer({
          source: new OSM()
        }),
        initalFeaturesLayer
      ],
      view: new View({
        center: fromLonLat([16.5825, 56.7761]),
        zoom: 9,
        minZoom: 2,
        maxZoom: 18,
      }),
      target: mapElementRef.current!
    });
    setOlMap(initialOlMap);
    setFeaturesLayer(initalFeaturesLayer);
    setPopup(popup);
  }, []);

  useEffect(() => {
    if (featuresLayer) {
      featuresLayer.setSource(
        new VectorSource({
          features: features
        })
      );
    }
  }, [featuresLayer, features]);

  useEffect(() => {
    if (selectedArtistId && features && olMap) {
      const selectedFeature: ArtistFeature | undefined = features.find((feature) => feature._id === selectedArtistId);
      let zoom = olMap.getView().getZoom();
      if (zoom && zoom < 12) zoom = 12;
      olMap.getView().animate({
        center: (selectedFeature?.getGeometry() as Point).getCoordinates(),
        zoom: zoom,
        duration: 1000
      });
      const popupOverlay = new Overlay({
        element: popupElementRef.current!,
      });
      popupOverlay.setPosition((selectedFeature?.getGeometry() as Point).getCoordinates());
      olMap?.addOverlay(popupOverlay);
      setPopupContent(
        <>
          <Link className="popup-link" to={`/artists/${selectedFeature?._id}`}>
            <p>{selectedFeature?.name}<br></br>{selectedFeature?.craft}</p>
            <p>Ateljéadress:<br></br>{selectedFeature?.town}<br></br>{selectedFeature?.street}</p>
          </Link>
        </>
      )
      dispatch(clearSelectedArtistId());
    }
    // eslint-disable-next-line
  }, [selectedArtistId, popupElementRef]);

  useEffect(() => {
    olMap?.on('click', (event) => olMap?.forEachFeatureAtPixel(event.pixel, (feature) => dispatch(setSelectedArtistId(feature.getId()))));
    return () => {
      
    }
    // eslint-disable-next-line
  }, [olMap]);

  return (
    <div className="OlMap">
      <div ref={mapElementRef} className="map-container"></div>
      <div ref={popupElementRef} className="popup">
        <div ref={popupContentRef} className="popup-content">
          {popupContent}
        </div>
      </div>
    </div>
  );
}

export default OlMap;
